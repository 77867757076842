import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { ContactComponent } from './contact/contact.component';
import { CarouselSliderComponent } from './carousel-slider/carousel-slider.component';
import { MetaService } from '@app/shared/services/meta/meta.service';
import { Meta } from '@angular/platform-browser';
import { HomeRoutes } from './home.routing';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgParticlesModule } from 'ng-particles';
import { GalleriaModule } from 'primeng/galleria';

@NgModule({
  imports: [
    CommonModule,
    HomeRoutes,
    FlexLayoutModule,
		NgParticlesModule,
		GalleriaModule
  ],
  declarations: [
    HomeComponent,
    CarouselSliderComponent,
    ContactComponent
  ],
  providers: [MetaService, Meta]
})
export class HomeModule { }
