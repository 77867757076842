import { Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  private pageTitle = new BehaviorSubject<string>('');
  currentTitle = this.pageTitle.asObservable();

  private pageMeta = new BehaviorSubject<MetaDefinition[]>([]);
  currentMeta = this.pageMeta.asObservable();

  constructor(private meta: Meta, private title: Title) { }

  receiveTitle(title: string): void {
    this.pageTitle.next(title);
  }

  receiveMeta(meta: MetaDefinition[]): void {
    this.pageMeta.next(meta);
  }

  updateTitle(title: string): void {
    this.title.setTitle(`Wellspring Dance Academy | ${ title }`);
  }

  updateMetaTags(metaTags: MetaDefinition[]): void {
    metaTags.forEach(m => this.meta.updateTag(m));
  }

  buildMetaTags(pageData: any): MetaDefinition[] {

    let metaDefinition: MetaDefinition[] = [
      { name: 'description', content: pageData.Description },
      { property: 'og:title', content: pageData.Title },
      { property: 'og:description', content: pageData.Description },
      { property: 'og:type', content: pageData.Type },
      { property: 'og:site_name', content: pageData.Site },
      { property: 'og:image', content: pageData.Image },
      { property: 'og:url', content: pageData.Url },
      { name: 'twitter:card', content: 'summary' },
      { name: 'twitter:title', content: pageData.Title },
      { name: 'twitter:description', content: pageData.Description },
      { name: 'twitter:image', content: pageData.Image },
      { name: 'twitter:site', content: pageData.Site },
    ];

    return metaDefinition;
  }
}
