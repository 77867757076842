import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
	AfterViewInit,
	Component,
	ElementRef,
	Inject,
	OnDestroy,
	PLATFORM_ID,
} from '@angular/core';
import { MetaDefinition } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { MetaService } from './shared/services/meta/meta.service';
import { HttpService } from './shared/services/http/http.service';
import { Observable, Subject } from 'rxjs';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnDestroy {
	public sliders$: Observable<any[]> = new Observable<any[]>();
	public destroy$: Subject<any> = new Subject();
	metaSubscription?: Subscription;
	pageTitle?: string;
	pageMeta?: MetaDefinition[];

	constructor(
		private metaService: MetaService,
		private router: Router,
		@Inject(PLATFORM_ID) private platformId: Object,
		private elementRef: ElementRef,
		@Inject(DOCUMENT) private document: any,
		private http: HttpService
	) {
		this.metaSubscription = this.metaService.currentTitle.subscribe(
			(title: string) => {
				this.metaService.updateTitle(title);
			}
		);

		this.metaSubscription = this.metaService.currentMeta.subscribe(
			(meta: MetaDefinition[]) => {
				this.metaService.updateMetaTags(meta);
			}
		);

		this.router.events.subscribe((evt: any) => {
			if (!(evt instanceof NavigationEnd)) {
				if (isPlatformBrowser(this.platformId)) {
					window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
				}

				// this.utilityService.loadTemplateScript();
			}
		});
	}

	ngAfterViewInit(): void {
		if (isPlatformBrowser(this.platformId)) {
			window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
		}
	}

	ngOnDestroy(): void {
		if (this.metaSubscription) {
			this.metaSubscription.unsubscribe();
		}

		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}
}
