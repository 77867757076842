import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment.development';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class HttpService {
	requestHeaders: HttpHeaders = new HttpHeaders();

	constructor(
		private http: HttpClient
	) { }

	public getData(endPointPrefix: string): Observable<any> {
		return this.http?.get(`${environment.WEB_API_Path}${endPointPrefix}`) as Observable<any>;
	}
}
