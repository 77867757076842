import { Component, HostListener } from '@angular/core';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
	public headerTransparent: boolean = true;

	@HostListener('window:scroll', ['$event'])
	onWindowsScroll(event: Event) {
		const scrollTop =
			window.pageYOffset ||
			document.documentElement.scrollTop ||
			document.body.scrollTop;

		if (scrollTop > 150) {
			this.headerTransparent = false;
		} else {
			this.headerTransparent = true;
		}
	}

	constructor() { }
}
