import { NgModule } from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
	HttpClientModule,
	provideHttpClient,
	withFetch,
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { HomeModule } from './pages/home/home.module';
import { FooterComponent } from './shared/components/footer/footer.component';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { HttpService } from './shared/services/http/http.service';
import { MetaService } from './shared/services/meta/meta.service';
import { HeaderModule } from './shared/components/header/header.module';

@NgModule({
	declarations: [
		AppComponent,
		FooterComponent,
		LoaderComponent,
		PageNotFoundComponent,
	],
	imports: [
		BrowserModule?.withServerTransition({ appId: 'serverApp' }),
		AppRoutingModule,
		HomeModule,
		BrowserAnimationsModule,
		HttpClientModule,
		FlexLayoutModule,
		TransferHttpCacheModule,
		HeaderModule
	],
	providers: [MetaService, Meta, HttpService, provideHttpClient(withFetch())],
	bootstrap: [AppComponent],
})
export class AppModule {}
