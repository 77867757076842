import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';

const routes: Routes = [
	{
		path: '',
		loadChildren: (): Promise<any> =>
			import('./pages/home/home.module')?.then((m) => m?.HomeModule),
	},
	{ path: '404', component: PageNotFoundComponent },
	{ path: '**', redirectTo: '404', pathMatch: 'full' },
];

@NgModule({
	imports: [
		RouterModule?.forRoot(routes, {
			initialNavigation: 'enabledNonBlocking',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
