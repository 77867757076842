import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { MetaService } from '@app/shared/services/meta/meta.service';
import { environment } from 'environments/environment.development';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnDestroy, OnInit {
	destroyed$ = new Subject();
	email = 'info@wellspringdanceacademy.co.za';

	constructor(private metaService: MetaService) {
		const meta: any = {
			Title: 'Home',
			Description: 'Home',
			Type: '',
			Site: 'Wellspring Dance Academy',
			Image: '',
			Url: `${environment.WEB_Site_Path}`,
		};

		this.metaService.updateTitle(meta.Title);
		this.metaService.updateMetaTags(this.metaService.buildMetaTags(meta));
	}

	ngOnInit(): void {
		this.getSliders();
	}

	getSliders(): void {}

	ngOnDestroy(): void {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}
}
